import React from "react";

import { useLocation } from "react-router-dom";
import classNames from "classnames";

import type { Node, AnnotatedPlan } from "../../types/explain";

import { useCostMetric } from "../WithExplainCostMetric";
import { Diff, diff } from "../../utils/diff";
import {
  DiffablePlan,
  DiffablePlanLine,
  DiffableSubPlan,
  findPlanIndexes,
  toDiffablePlan,
} from "../../utils/explain";
import { formatNumber, formatMs } from "../../utils/format";
import { useSelectedNode } from "components/Explain/WithNodeSelection";
import Button from "components/Button";
import Callout from "components/Callout";

export const ExplainDiff = ({
  planA,
  planB,
}: {
  planA: AnnotatedPlan;
  planB: AnnotatedPlan;
}) => {
  const planAIndexes = findPlanIndexes(planA.plan);
  const planBIndexes = findPlanIndexes(planB.plan);
  const allIndexes = Array.from(
    new Set(Array.from(planAIndexes).concat(Array.from(planBIndexes))),
  ).sort();
  const diffableA = toDiffablePlan(planA.plan, allIndexes);
  const diffableB = toDiffablePlan(planB.plan, allIndexes);

  function comparePlans(a: DiffablePlanLine, b: DiffablePlanLine) {
    return (
      a.line.trimStart() === b.line.trimStart() &&
      a.node["Index Name"] === b.node["Index Name"] &&
      a.node["Relation Name"] === b.node["Relation Name"] &&
      a.node.Relation === b.node.Relation
    );
  }
  const rootDiff = diff(diffableA.root, diffableB.root, comparePlans);

  const subPlans = getComparableSubplans(diffableA, diffableB);
  const subplanDiffs = Object.entries(subPlans).map(
    ([name, [subplanA, subplanB]]) => {
      return [
        name,
        diff(subplanA ?? [], subplanB ?? [], comparePlans),
      ] as const;
    },
  );

  const hasDiff =
    rootDiff.some((val) => val.kind != "equal") ||
    subplanDiffs.some(([_subPlanName, diff]) => {
      return diff.some((val) => val.kind != "equal");
    });

  const metric = useCostMetric();

  return (
    <div className="px-4 pb-4">
      {!hasDiff && (
        <Callout className="mb-4">
          Both plans have an identical structure
        </Callout>
      )}
      <table className="w-full table-fixed border-separate">
        <tbody className="whitespace-pre font-mono">
          <tr>
            <th className="overflow-hidden text-ellipsis w-1/3">Plan A</th>
            <th className="overflow-hidden text-ellipsis w-1/3">Plan B</th>
            <th className="overflow-hidden text-ellipsis text-right w-1/6">
              Plan A: {metric}
            </th>
            <th className="overflow-hidden text-ellipsis text-right w-1/6">
              Plan B: {metric}
            </th>
          </tr>
          <SubplanDiff planDiff={rootDiff} />
          {subplanDiffs.map(([name, subplanDiff]) => {
            return (
              <React.Fragment key={name}>
                <tr>
                  <th className="pt-2 overflow-hidden text-ellipsis">{name}</th>
                </tr>
                <SubplanDiff planDiff={subplanDiff} />
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

function getComparableSubplans(planA: DiffablePlan, planB: DiffablePlan) {
  // We need to diff Plan A's subplans with Plan B's subplans, but we don't want
  // to just rely on the source ordering to avoid comparing unrelated subplans
  // if they are reordered. Use subplan names to match these instead.
  function mapSubPlansByName(
    map: { [name: string]: DiffableSubPlan },
    subPlan: DiffableSubPlan,
    i: number,
  ) {
    const name =
      (subPlan[0].content.node["Subplan Name"] as string) ??
      `Unknown Subplan ${i}`;
    map[name] = subPlan;
    return map;
  }
  const subPlansAbyName = planA.subPlans.reduce(mapSubPlansByName, {});
  const subPlansBbyName = planB.subPlans.reduce(mapSubPlansByName, {});
  const allSubplanNames = new Set(
    Object.keys(subPlansAbyName).concat(Object.keys(subPlansBbyName)),
  );
  const subPlans = Array.from(allSubplanNames).reduce(
    (map, name) => {
      const comparablePair = [
        subPlansAbyName[name] ?? [],
        subPlansBbyName[name] ?? [],
      ] as const;
      map[name] = comparablePair;
      return map;
    },
    {} as {
      [name: string]: readonly [
        planASubplan: DiffableSubPlan,
        planBSubplan: DiffableSubPlan,
      ];
    },
  );

  return subPlans;
}

function SubplanDiff({ planDiff }: { planDiff: Diff<DiffablePlanLine> }) {
  const { hash } = useLocation();
  const usingComparisonPlan = hash.includes("compare");
  const [selectedNode, setSelected] = useSelectedNode();
  const selectedId = selectedNode?.extra.id;

  return (
    <>
      {planDiff.map((edit, idx) => {
        const aSelected =
          !usingComparisonPlan &&
          edit.kind != "insert" &&
          edit.a.content.node.extra.id === selectedId;
        const bSelected =
          usingComparisonPlan &&
          edit.kind != "delete" &&
          edit.b.content.node.extra.id === selectedId;
        switch (edit.kind) {
          case "insert":
            return (
              <tr className="hover:bg-slate-100" key={idx}>
                <td></td>
                <td
                  className={classNames(
                    "overflow-hidden bg-green-300 border-l-4",
                    bSelected ? "border-[#337ab7]" : "border-green-300",
                  )}
                >
                  <Button
                    bare
                    className="w-full text-left overflow-hidden text-ellipsis"
                    onClick={() =>
                      setSelected(edit.b.content.node.extra.id, "comparison")
                    }
                  >
                    {edit.b.content.line}
                    <sup>{edit.b.content.footnote}</sup>
                  </Button>
                </td>
                <td className="text-right"></td>
                <td className="text-right">
                  <CostFormatted node={edit.b.content.node} />
                </td>
              </tr>
            );
          case "delete":
            return (
              <tr className="hover:bg-slate-100" key={idx}>
                <td
                  className={classNames(
                    "overflow-hidden bg-red-300 border-l-4",
                    aSelected ? "border-[#337ab7]" : "border-red-300",
                  )}
                >
                  <Button
                    bare
                    className="w-full text-left overflow-hidden text-ellipsis"
                    onClick={() =>
                      setSelected(edit.a.content.node.extra.id, "main")
                    }
                  >
                    {edit.a.content.line}
                    <sup>{edit.a.content.footnote}</sup>
                  </Button>
                </td>
                <td></td>
                <td className="text-right">
                  <CostFormatted node={edit.a.content.node} />
                </td>
                <td className="text-right"></td>
              </tr>
            );
          case "equal":
            return (
              <tr className="hover:bg-slate-100" key={idx}>
                <td
                  className={classNames(
                    "overflow-hidden border-l-4",
                    aSelected ? "border-[#337ab7]" : "border-transparent",
                  )}
                >
                  <Button
                    bare
                    className="w-full text-left overflow-hidden text-ellipsis"
                    onClick={() =>
                      setSelected(edit.a.content.node.extra.id, "main")
                    }
                  >
                    {edit.a.content.line}
                    <sup>{edit.a.content.footnote}</sup>
                  </Button>
                </td>
                <td
                  className={classNames(
                    "overflow-hidden border-l-4",
                    bSelected ? "border-[#337ab7]" : "border-transparent",
                  )}
                >
                  <Button
                    bare
                    className="w-full text-left overflow-hidden text-ellipsis"
                    onClick={() =>
                      setSelected(edit.b.content.node.extra.id, "comparison")
                    }
                  >
                    {edit.b.content.line}
                    <sup>{edit.b.content.footnote}</sup>
                  </Button>
                </td>
                <td className="text-right">
                  <CostFormatted node={edit.a.content.node} />
                </td>
                <td className="text-right">
                  <CostFormatted node={edit.b.content.node} />
                </td>
              </tr>
            );
        }
      })}
    </>
  );
}

const CostFormatted = ({ node }: { node: Node }) => {
  const metric = useCostMetric();

  if (metric === "Est. Cost") {
    const cost = node.extra.self["Total Cost"];
    return cost != null ? formatNumber(cost, 2) : "-";
  } else if (metric === "Runtime") {
    const cost = node.extra.self["Actual Total Time"];
    return cost != null ? formatMs(cost) : "-";
  } else if (metric === "I/O Time") {
    const cost = node.extra.self["I/O Read Time"];
    return cost != null ? formatMs(cost) : "-";
  } else if (metric == "Rows") {
    const cost = node["Actual Rows"];
    return cost != null ? formatNumber(cost) : "-";
  } else {
    throw new Error("unsupported metric");
  }
};

export default ExplainDiff;
