import React from "react";
import { useParams } from "react-router-dom";

import { ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType } from "../ExplainWorkbook/types/ExplainWorkbookDetails";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import { ExplainComparisonWorkbook } from "components/ExplainPanel/ExplainComparison";

const ComparePlansPanel = ({
  workbook,
  blockSize,
}: {
  workbook: ExplainWorkbookType;
  blockSize: number;
}) => {
  const { databaseId } = useParams();

  return (
    <>
      {/* TODO: On Firefox, the top border of the panel is cut off without an extra margin
        here because we're using box-shadow to draw the border; this should not be necessary. */}
      <Panel className="mt-[1px]" title="Comparison">
        <PanelSection>
          <ExplainComparisonWorkbook
            databaseId={databaseId}
            workbookId={workbook.id}
            blockSize={blockSize}
          />
        </PanelSection>
      </Panel>
    </>
  );
};

export default ComparePlansPanel;
